import { Button, Icon, IconButton } from "@mui/material";
import "./Web19201.css";
const Web19201 = () => {
  return (
    <div className="web-1920-1">
      <div className="background">
        <img className="mask-group-1" alt="" src="/mask-group-1@2x.png" />
        <img className="mask-group-2" alt="" src="/mask-group-2@2x.png" />
        <img className="background-child" alt="" src="/group-21.svg" />
        <div className="path-326-parent">
          <img className="path-326-icon" alt="" src="/path-326.svg" />
          <img className="path-327-icon" alt="" src="/path-327.svg" />
        </div>
        <img className="background-item" alt="" src="/group-49.svg" />
        <img className="background-inner" alt="" src="/group-51.svg" />
      </div>
      <main className="header">
        <img className="header-child" alt="" src="/group-13.svg" />
        <img className="header-item" alt="" src="/group-11@2x.png" />
        <img className="biryani-icon" alt="" src="/biryani@2x.png" />
        <div className="path-1-wrapper">
          <img className="path-1-icon" alt="" src="/path-1.svg" />
        </div>
        <img className="brocali-icon" alt="" src="/brocali.svg" />
        <b className="eat-healthy-be-healthy">
          <p className="eat">EAT</p>
          <p className="healthy">HEALTHY</p>
          <p className="eat">BE HEALTHY..!</p>
        </b>
        <b className="download-the-app">DOWNLOAD THE APP</b>
        <b className="lorem-ipsum-is-container">
          <p className="eat">
          Get your customized healthy diet by expert dietitians at your doorstep.
          </p>
          <p className="eat">
          Freshly prepared and hygienic food, according to your body needs.
          </p>
          <p className="eat">{`Does your food lack proteins and other important nutritions? We got you covered. `}</p>
          <p className="eat">{`Start your subscription today, `}</p>
          <p className="eat"></p>
        </b>
        <a className="download" href="https://onelink.to/3mfp4w" />
        <a className="appstore" href="https://onelink.to/3mfp4w" />
      </main>
      <nav className="navigation">
        <img className="navigation-child" alt="" src="/ellipse-10.svg" />
        <img className="navigation-item" alt="" src="/ellipse-9.svg" />
        <img className="png1-icon" alt="" src="/png1@2x.png" />
        <a className="dishes">DISHES</a>
        <a className="contact">CONTACT</a>
        <a className="services">SERVICES</a>
        <a className="about">ABOUT</a>
      </nav>
      <footer className="footer">
        <a className="dishes1">DISHES</a>
        <a className="contact1">CONTACT</a>
        <a className="services1">SERVICES</a>
        <a className="about1">ABOUT</a>
        <b className="follow-us">FOLLOW US</b>
        <div className="footer-child" />
        <a className="instagram-1" />
        <a className="facebook-logo" />
        <a className="linkedin-1" />
      </footer>
      <div className="services2">
        <b className="our-services">OUR SERVICES</b>
        <div className="services-child" />
        <img className="path-332-icon" alt="" src="/path-332.svg" />
        <div className="services-item" />
        <div className="services-inner" />
        <img className="rectangle-icon" alt="" src="/rectangle-16@2x.png" />
        <img className="services-child1" alt="" src="/rectangle-16@2x.png" />
        <b className="lorem-ipsum-is-container1">
          <p className="eat">{`All you have to do is `}</p>
          <p className="eat">{`take a subscription `}</p>
          <p className="eat">{`and give details `}</p>
          <p className="eat">to our dietician</p>
        </b>
        <b className="lorem-ipsum-is-container2">
          <p className="eat">{`We will use the best `}</p>
          <p className="eat">{`and fresh ingredients `}</p>
          <p className="eat">{`to cook your nutritious `}</p>
          <p className="eat">and delecious meal</p>
        </b>
        <b className="lorem-ipsum-is-container3">
          <p className="eat">{`Our delivery partners `}</p>
          <p className="eat">{`make sure hygienic `}</p>
          <p className="eat">{`get your meal to your `}</p>
          <p className="eat">place on time.</p>
        </b>
        <div className="ellipse-parent">
          <img className="group-child" alt="" src="/ellipse-6.svg" />
          <img className="group-item" alt="" src="/group-39.svg" />
        </div>
        <div className="ellipse-group">
          <img className="group-inner" alt="" src="/ellipse-8.svg" />
          <img className="group-icon" alt="" src="/group-46.svg" />
        </div>
        <img className="easy-order-icon" alt="" src="/easy-order@2x.png" />
      </div>
      <div className="customer">
        <div className="customer-child" />
        <img
          className="portrait-successful-man-having-icon"
          alt=""
          src="/portraitsuccessfulmanhavingstubbleposingwithbroadsmilekeepingarmsfolded@2x.png"
        />
        <img
          className="close-up-portrait-nice-cute-ad-icon"
          alt=""
          src="/closeupportraitnicecuteadorablesmilingcharmingcheerfulgirlpointingwithherindexfinger@2x.png"
        />
        <img
          className="smiling-confident-businesswoma-icon"
          alt=""
          src="/smilingconfidentbusinesswomanposingwitharmsfolded@2x.png"
        />
        <b className="lorem-ipsum-is-container4">
          <p className="eat">
            Lorem Ipsum is simply dummied text of the printing and typesetting
            industry.
          </p>
          <p className="eat">
            Lorem Ipsum has been the industry's standard dummy text ever since
            the 1500s when
          </p>
          <p className="eat">{`an unknown printer took a galley of type and scrambled it to make a type specimen book. `}</p>
          <p className="eat">{`It has survived not only five centuries, but also the leap into electronic typesetting, `}</p>
          <p className="eat">remaining essentially unchanged.</p>
        </b>
        <b className="client-review">CLIENT REVIEW</b>
        <i className="i">66</i>
        <div className="customer-item" />
        <div className="layer-2">
          <Button
            className="arrow-ios-back"
            sx={{ width: 66 }}
            variant="outlined"
            color="primary"
          />
        </div>
        <img className="customer-inner" alt="" src="/ellipse-5.svg" />
        <img className="ellipse-icon" alt="" src="/ellipse-4.svg" />
        <img className="customer-child1" alt="" src="/group-19@2x.png" />
        <img className="customer-child2" alt="" src="/group-29.svg" />
        <img className="customer-child3" alt="" src="/group-26.svg" />
        <img className="c1-icon" alt="" src="/c1@2x.png" />
        <img className="customer-child4" alt="" src="/group-28@2x.png" />
        <div className="group-div">
          <img className="group-child1" alt="" src="/group-12.svg" />
        </div>
        <div className="path-331-wrapper">
          <img className="path-331-icon" alt="" src="/path-331.svg" />
        </div>
      </div>
      <div className="special-dishes">
        <b className="our-special-dishes">OUR SPECIAL DISHES</b>
        <img className="special-dishes-child" alt="" src="/group-14.svg" />
        <img className="special-dishes-item" alt="" src="/group-11@2x.png" />
        <div className="special-dishes-inner" />
        <div className="rectangle-div" />
        <b className="coconut-poha">COCONUT POHA</b>
        <div className="coconut-poha1">COCONUT POHA</div>
        <div className="coconut-poha2">COCONUT POHA</div>
        <b className="b">199/-</b>
        <b className="b1">199/-</b>
        <b className="bangali-macchi-bhat">
          <p className="eat">BANGALI MACCHI</p>
          <p className="eat">BHAT</p>
        </b>
        <img className="biryani-icon1" alt="" src="/biryani1@2x.png" />
        <img className="poha-icon" alt="" src="/poha@2x.png" />
        <img className="special-dishes-child1" alt="" src="/ellipse-1.svg" />
        <IconButton className="layer-21" color="primary">
          <Icon>arrow_back_ios_sharp</Icon>
        </IconButton>
        <IconButton className="layer-22" color="primary">
          <Icon>arrow_forward_ios_sharp</Icon>
        </IconButton>
        <div className="special-dishes-child2" />
        <div className="coconut-poha3">COCONUT POHA</div>
        <b className="b2">199/-</b>
        <b className="chicken-daliya">CHICKEN DALIYA</b>
        <img className="daliya-icon" alt="" src="/daliya@2x.png" />
        <img className="special-dishes-child3" alt="" src="/ellipse-1.svg" />
        <img className="special-dishes-child4" alt="" src="/group-16.svg" />
        <img className="special-dishes-child5" alt="" src="/ellipse-1.svg" />
        <img className="special-dishes-child6" alt="" src="/group-18.svg" />
      </div>
    </div>
  );
};

export default Web19201;
